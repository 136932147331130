.ReactModal__Body--open {
  overflow: hidden;
}

.content-button-familiarized {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 15px;
}

.notification-title {
  font-size: 24px !important;
  margin-bottom: 10px !important;
  text-decoration: underline;
}

.courses-popup-wrap {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;
  color: #404040;
  font-size: 20px;
  font-weight: 700;
}

.stat-courses-popup .popup-head {
  margin-bottom: 20px !important;
  padding: 20px 20px !important;
}

.close-modal-button {
  position: absolute;
  top: 0;
  right: 0;
  width: 44px;
  height: 44px;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  outline: none;
  background: transparent;
  z-index: 10;
  cursor: pointer;
}

.close-modal-button:after {
  content: "×";
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 30px;
  font: 20px/30px Arial, Helvetica Neue, Helvetica, sans-serif;
  color: #888;
  font-weight: 300;
  text-align: center;
  border-radius: 50%;
  border-width: 0;
  background: #fff;
  transition: background .25s;
  box-sizing: border-box;
  z-index: 2;
}

.modal-popup {
  margin: 44px 0;
}

.familiarized {
  cursor: pointer;
  border-bottom: 1px dashed #BCBCBC;
  color: #BCBCBC;
  font-size: 18px;
  float: left;
  margin-top: 16px;
  -webkit-transition: all 200ms;
}
