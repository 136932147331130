.moduleBtnDone {
  display: inline-block;
  text-decoration: none;
  background-color: #4b8dff;
  color: #ffffff;
  border: 2px solid #4b8dff;
  border-radius: 5px;
  font-size: 20px;
  padding: 2px 20px; 
  transition: all 0.2s ease;
}
.moduleBtnDone:hover{
  text-decoration: none; 
  background-color: #0163ff;
  color: #ffffff;
  border-color: #0163ff;
}

.moduleBtnReset {
  display: inline-block;
  text-decoration: none;
  background-color: #ffffff;
  color: #4b8dff;
  border: 2px solid #4b8dff;
  border-radius: 5px;
  font-size: 20px;
  padding: 2px 20px; 
  transition: all 0.2s ease;
}
.moduleBtnReset:hover{
  text-decoration: none; 
  background-color: #4b8dff;
  color: #ffffff;
  border-color: #4b8dff
}