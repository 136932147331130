.lesson-description code {
  color: #3b3b3b;
  background: #fafafa;
  padding: 1px 8px;
  font-size: 90%;
  border-radius: 5px;
}

pre {
  background: #fafafa !important;
  margin-top: 10px !important;
}

.answer-question {
  background: #FAFAFA;
  margin-top: 30px;
  border: 3px solid #ffbc00;
  border-radius: 6px;
  padding: 27px 25px;
  white-space: nowrap;
  margin-bottom: 20px;
}

.answer-question.error {
  border: 3px solid #FF3B5E;
}

.answer-question.success {
  border: 3px solid #00C832;
}

.mentor-description {
  white-space: break-spaces;
}
