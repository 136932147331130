.ReactModal__Body--open {
    overflow: hidden;
}
.close-modal-button {
    position: absolute;
    top: 0;
    right: 0;
    width: 44px;
    height: 44px;
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 0;
    outline: none;
    background: transparent;
    z-index: 10;
    cursor: pointer;
}

.close-modal-button:after {
    content: "×";
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    font: 20px/30px Arial,Helvetica Neue,Helvetica,sans-serif;
    color: #888;
    font-weight: 300;
    text-align: center;
    border-radius: 50%;
    border-width: 0;
    /*background: #ffffff;*/
    transition: background .25s;
    box-sizing: border-box;
    z-index: 2;
}

.stat-courses-popup .popup-head {
    margin: 0 !important;
    padding: 15px !important;
    margin-bottom: 0 !important;
    border-bottom: 1px solid #e5e5e5;
}

.popup-window-premoderation {
    position: relative;
    padding: 0;
    background-color: white;
}

.modal-popup {
    margin: 44px 0;
}

.modal-title {
    text-align: left;
}
