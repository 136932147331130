$base-calendar-width: 75px;
$base-calendar-height: 75px;
$base-calendar-radius: 50%;
$base-calendar-bg: #F6F6F6;
$base-calendar-fs: 25px;
$base-calendar-bg-active: #D9D9D9;
$base-calendar-text-active: #0f0f0f;
$base-calendar-padding: 15px 15px;
$base-calendar-text-disabled: rgba(16, 16, 16, 0.3);

$review-primary: #4ea3ff;
$review-warn: #ffac30;
$review-hover: #dbeafc;
$review-disabled: #C4C4C4;


@mixin container-step-modal($fs) {
  min-width: 100%;
  background-color: $base-calendar-bg;
  padding: $base-calendar-padding;
  border: none;
  font-size: $fs;
}

@mixin center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar-wrapper {
  @include center()
}

.calendar {
 @include container-step-modal($base-calendar-fs)
}

.sign_up-review,
.not-found {
  height: 550px;
  @include container-step-modal($base-calendar-fs);
}

.not-found {
  @include center();
}

.not-found-title {
  font-size: large;
}

.sign_up-inner-container {
  max-width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.sign_up-about {
  text-align: center;
  margin-bottom: auto;
}

.sign_up-title {
  margin-bottom: 15px;
  font-size: large;
}

.sign_up-date {
  display: flex;
  flex-direction: column;
}

.sign_up-date > button {
  margin-bottom: 10px;
}


.action-button {
  display: block;
  font-size: 18px;
  color: $review-primary;
  border: solid 1px $review-primary;
  border-radius: 10px;
  min-height: 50px;
  background: transparent;
  transition: all .2s ease-in;
}

.action-button.action-time-button:hover {
  background: $review-hover;
}

.action-button.action-time-button:active,
.action-button.action-time-button:focus,
.action-button.selected{
  background: $review-primary;
  color: white;
}

.action-button.selected:hover {
  color: $base-calendar-text-active;
}

.action-button.action-time-button:disabled {
  pointer-events: none;
  background: transparent;
  border-color: $review-disabled;
  color: $review-disabled;
  opacity: 0.5;
}

.sing_up-actions {
  display: flex;
}

.action-button-primary {
  background-image: linear-gradient(-48deg, #4b8aff 0%, #407ef2 100%);
  margin-right: 16px;
}

.action-button-success {
  background-color: #59da7a;
}

.action-button-primary,
.action-button-success {
  width: 250px;
  color: white;
  border: none;
}

.cancel-sign_up {
  margin-bottom: 15px;
}

.cancel-sign_up > button {
  width: 100%;
}

.action-button-warn {
  background: $review-warn;
  border: 1px solid $review-warn;
  color: white;
}

// -------------- redefine styles from react-calendar ------------------ //
.react-calendar__tile {
  border-radius: $base-calendar-radius;
  min-width: $base-calendar-width;
  min-height: $base-calendar-height;
  margin: 0;
  padding: 0;
}

.react-calendar__tile--active {
  background: $base-calendar-bg-active!important;
  color: $base-calendar-text-active;
  text-decoration: underline;
}

.react-calendar__tile:disabled {
  background: $base-calendar-bg!important;
}

.react-calendar__tile:disabled > abbr {
  color: $base-calendar-text-disabled;
}

.react-calendar__tile--now {
  background: $base-calendar-bg-active!important;
}

.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button{
  background: transparent!important;
  pointer-events: none;
}
.stat-courses-popup .popup-head {
  margin-bottom: 0!important;
}

.available-for-review {
  background: #59DA7A!important;
}

.react-calendar__tile.react-calendar__year-view__months__month {
  padding: 5px;
  text-align: center;
  @include center();
}

.react-calendar__navigation__label {
  pointer-events: none!important;
}

.react-calendar__tile--active:hover {
  text-decoration: underline!important;
}
// --------------------------------------------------------------------- //
