@import "../constants";
.filters {
  display: grid;
  grid-template-columns: 1fr 2fr;
  height: 100px;
  padding:0 20px;
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    height:auto;
    justify-content: center;
  }
}

.filter-button {
  flex-basis: 35%;
  height: 100px;
  max-width: 405px;
  margin-right: 20px;
}

.add-report-button {
  margin-top: 20px;
  min-width: 380px;
  width: 100%;
  height: 60px;
  padding: 0;
  border: 2px solid  $mainBlue;
  border-radius: 10px;
  font: inherit;
  color: $mainBlue;
  font-size: 18px;
  line-height: 21px;
  font-weight: 700;
  background-color: transparent;
  cursor: pointer;
  transition: .2s ease-in;
  &:hover{
    background-color:$mainBlue;
    color: #fff;
  }
}

.filter-search {
  width: 100%;
}

.search-input {
  max-width: 405px;
}


.search-date {
  width: 200px;
  height: 35px;
  flex-basis: 50%;
  width: auto;
}

.search-form {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    justify-content: center;
  }

}

.public-date {
  padding: 0 15px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid $secondGray;
  width: auto;
  height: 34px;
  transition: border .2s ease-in;
  &:hover{
    cursor: pointer;
    border: 1px solid $mainBlue;
  }
}

.search-intervals {
  display: flex;
  gap:16px;
  color: $mainBlue;
  transition: color 0.2s ease-in;
  &:hover {
    color: $secondGray;
  }

}

.search-interval {
  padding: 0;
  border: none;
  font: inherit;
  background-color: transparent;
  cursor: pointer;
  font-size: 17px;
  font-weight: 700;
  transition: color 0.2s ease-in;
  &:hover{
    color:$mainBlue;
  }
}

.between-date {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  gap: 10px;
}

.search-wrap {
  width: 100%;
  vertical-align: middle;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
}

.search {
  width: 90%;
  height: 34px;
  border: 1px solid $firsGray;
  box-sizing: border-box;
  font-size: 16px;
  padding-left: 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  transition: border 0.2s ease-in;
  &:hover{
    border: 1px solid $mainBlue;
  }
}

.search-icon {
  margin: 5px;
}

.checkbox-wrap {
  display: flex;
  margin-top: 10px;
  position: relative;
}

.checkbox-wrap input {
  height: 40px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 40px;
}
.checkbox-wrap label {
  margin-left: 28px;
  font-size: 16px;
  font-weight: normal;
  Line-height:  19px;
  cursor:pointer;
  transition: color 0.2s ease-in-out;
}
.checkbox-wrap input + label::before {
  border: 1px solid #CAC9C9;
  border-radius: 2px;
  content: "";
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  width: 18px;
  transition: 0.2s ease-in-out;
}
.checkbox-wrap input + label::after {
  content: "";
  border: 3px solid $mainBlue;
  border-left: 0;
  border-top: 0;
  height: 10px;
  left: 6px;
  opacity: 0;
  position: absolute;
  top: 3px;
  transform: rotate(45deg);
  transition:  0.2s ease-in-out;
  width: 6px;
}
.checkbox-wrap input:checked + label::after {
  opacity: 1;
}
.checkbox-wrap input:checked + label::before {
  border: 1px solid $mainBlue;
}
.checkbox-wrap input:hover + label::before {
  border: 1px solid $mainBlue;
}
.checkbox-wrap input:hover + label {
  color:$mainBlue;
}
